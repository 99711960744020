import cx from 'classnames';

export default function Component({ onClick, text, disable }) {
  return (
    <button
      onClick={() => onClick()}
      disabled={disable}
      className={cx(
        'w-max border-none px-24 py-3 text-white text-base lg:text-2xl font-semibold rounded-3xl ',
        disable
          ? 'bg-disable'
          : 'bg-gradient-to-r from-secondary-90 to-secondary-80 hover:from-secondary-100 hover:to-secondary-90'
      )}
    >
      {text}
    </button>
  );
}
