import { useNavigate } from 'react-router-dom';
import { homeImage, ifgLogo } from '../../../assets/images';
import { Button, Container } from '../../../components';

export default function Page() {
  const navigate = useNavigate();

  return (
    <Container
      className='relative flex flex-col lg:flex-row-reverse bg-primary-90 h-screen'
      isPrimary
    >
      <section className='w-full h-1/2 lg:h-auto'>
        <img
          className='absolute top-0 right-0 max-h-[60%] lg:max-h-full'
          src={homeImage}
          alt=''
        />
      </section>
      <section className='w-full h-1/2 lg:h-auto lg:items-center'>
        <div className='w-full h-full flex justify-center lg:justify-end items-start sm:items-center items-center py-4 sm:pt-0 lg:pr-24'>
          <div>
            <img className='w-[120px] xl:w-[240px] mb-4 m-auto xl:m-0' src={ifgLogo} alt='' />
            <div className='text-center lg:text-left text-white font-bold mb-8 xl:mt-6 mt-0'>
              <div className='font-semibold text-3xl xl:text-5xl mb-2'>Protection</div>
              <div className='font-semibold text-xl xl:text-4xl'>has never been easier</div>
            </div>
            <Button
              onClick={() => navigate('/product/easy-trip')}
              text='Join Now'
            />
          </div>
        </div>
      </section>
      <section className='absolute w-full bottom-2 text-white text-[8px] sm:text-base md:text-2xl text-center py-2 bg-90'>
        EasyPro by IFG Life. PT Asuransi Jiwa IFG terdaftar dan diawasi oleh
        OJK.
      </section>
    </Container>
  );
}
