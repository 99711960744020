/* eslint-disable react-hooks/exhaustive-deps */
import { Icon } from 'react-icons-kit';
import { chevronDown } from 'react-icons-kit/fa/chevronDown';
import { search } from 'react-icons-kit/fa/search';
import { useEffect, useState } from 'react';

export default function Component({ title, options = [], value, onSelect }) {
  const [isActive, setActive] = useState(false);
  const [searchKey, setSearchKey] = useState('');
  const [dataOptions, setDataOptions] = useState([]);

  useEffect(() => {
    if (!searchKey) {
      setDataOptions(options);
    } else {
      const dataOptionsFiltered = dataOptions?.filter((e) =>
        e?.label?.toLowerCase().includes(searchKey.toLowerCase())
      );

      setDataOptions(dataOptionsFiltered);
    }
  }, [searchKey]);

  return (
    <div className='flex text-white items-center text-sm sm:text-base md:text-xl lg:text-2xl gap-2 md:gap-4 font-semibold'>
      <div>{title}</div>
      <div className='relative w-[240px] md:w-[320px]'>
        <div
          role='button'
          onClick={() => setActive(!isActive)}
          className='h-10 border-b-2 border-white flex justify-between items-center px-2'
        >
          <div className='text-base sm:text-base md:text-base md:text-lg lg:text-xl font-normal w-full text-center'>
            {value?.label}
          </div>
          <Icon className='-mt-2 scale-100 md:scale-150' icon={chevronDown} />
        </div>
        {isActive ? (
          <div className='absolute z-10 w-full flex flex-col gap-2 bg-primary-20 rounded-b-3xl p-4'>
            <div className='flex gap-3 md:gap-6 bg-white rounded-lg items-center'>
              <input
                className='rounded-lg w-full focus:outline-none text-black px-2 md:px-4 py-1 text-base md:text-base md:text-2xl'
                type='text'
                value={searchKey}
                onChange={(e) => setSearchKey(e?.target?.value)}
              />
              <div className='mr-4 -mt-1 '>
                <Icon
                  className='text-black scale-100 md:scale-150'
                  icon={search}
                />
              </div>
            </div>
            <div className='flex flex-col gap-1 md:gap-2 max-h-40 overflow-auto'>
              {dataOptions?.map((e, i) => (
                <div
                  key={i}
                  role='button'
                  onClick={() => {
                    onSelect(e);
                    setActive(false);
                  }}
                  className='text-center text-black'
                >
                  {e?.label}
                </div>
              ))}
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
}
