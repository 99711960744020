import { Icon } from 'react-icons-kit';
import { chevronDown } from 'react-icons-kit/fa/chevronDown';
import cx from 'classnames';
import { useState } from 'react';

export default function Component({ options, title, onSelect, value }) {
  const [isActive, setActive] = useState(false);

  return (
    <div className='w-full max-w-[240px] md:max-w-[320px]'>
      {title ? (
        <div className='text-white text-center font-normal text-base md:text-2xl mb-0 md:mb-2'>
          {title}
        </div>
      ) : null}
      <div className='relative h-10'>
        <div
          role='button'
          onClick={() => setActive(!isActive)}
          className={cx(
            'absolute top-0 px-4 w-full h-10 md:h-12 bg-white rounded-3xl flex justify-between items-center cursor-pointer',
            isActive ? 'z-30' : 'z-10'
          )}
        >
          <div className='w-full text-center text-base md:text-2xl font-semibold'>
            {value?.label}
          </div>
          <Icon className='-mt-1 scale-100 md:scale-150' icon={chevronDown} />
        </div>
        <div>
          {isActive ? (
            <div
              className={cx(
                'absolute mt-2 md:mt-6 bg-primary-20 w-full py-2 md:py-3 rounded-b-3xl flex flex-col gap-1',
                isActive ? 'z-20' : 'z-10'
              )}
            >
              {options?.map((e, i) => (
                <div
                  key={i}
                  role='button'
                  onClick={() => {
                    onSelect(e);
                    setActive('');
                  }}
                  onBlur={() => setActive(false)}
                  className={cx(
                    'text-base md:text-2xl text-center cursor-pointer',
                    {
                      'mt-6': !i,
                    }
                  )}
                >
                  {e?.label}
                </div>
              ))}
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
}
