import { useState } from "react";
import {
  easyTripHeader,
  easyTripHeaderDesktop,
} from "../../../../assets/images";
import RIPLAY from "../../../../assets/pdf/RIPLAY_Easy_Trip_convert_2.pdf";
import { Button, Container } from "../../../../components";
import { Icon } from "react-icons-kit";
import { chevronLeft } from "react-icons-kit/fa/chevronLeft";
import { useNavigate, useLocation } from "react-router-dom";
import moment from "moment";
import axios from "axios";
import DateTimePicker from "react-datetime-picker";
import { BASE_URL } from "../../../../utils/constants";

const year = new Date().getFullYear();
const month = new Date().getMonth() + 1;
const day = new Date().getDate();
const hour = new Date().getHours();
const minute = new Date().getMinutes();

export default function Page() {
  const navigate = useNavigate();
  const { state } = useLocation();
  const [dataInput, setDataInput] = useState({
    tglMulaiAsuransi: moment().format("YYYY-MM-DD[T]HH:mm"),
    uangPertanggungan: 50000000,
    dateSafari: new Date(),
    dateChrome: `${year}-${month < 10 ? `0${month}` : month}-${
      day < 10 ? `0${day}` : day
    }T${hour < 10 ? `0${hour}` : hour}:${minute < 10 ? `0${minute}` : minute}`,
  });
  const [loading, setLoading] = useState(false);

  const browserVersion = (userAgent, regex) => {
    return userAgent.match(regex) ? userAgent.match(regex)[2] : null;
  };

  const getBrowser = () => {
    const userAgent = navigator.userAgent;
    let browser = "unkown";
    // Detect browser name
    browser = /ucbrowser/i.test(userAgent) ? "UCBrowser" : browser;
    browser = /edg/i.test(userAgent) ? "Edge" : browser;
    browser = /googlebot/i.test(userAgent) ? "GoogleBot" : browser;
    browser = /chromium/i.test(userAgent) ? "Chromium" : browser;
    browser =
      /firefox|fxios/i.test(userAgent) && !/seamonkey/i.test(userAgent)
        ? "Firefox"
        : browser;
    browser =
      /; msie|trident/i.test(userAgent) && !/ucbrowser/i.test(userAgent)
        ? "IE"
        : browser;
    browser =
      /chrome|crios/i.test(userAgent) &&
      !/opr|opera|chromium|edg|ucbrowser|googlebot/i.test(userAgent)
        ? "Chrome"
        : browser;
    browser =
      /safari/i.test(userAgent) &&
      !/chromium|edg|ucbrowser|chrome|crios|opr|opera|fxios|firefox/i.test(
        userAgent
      )
        ? "Safari"
        : browser;
    browser = /opr|opera/i.test(userAgent) ? "Opera" : browser;

    // detect browser version
    switch (browser) {
      case "UCBrowser":
        return `${browser}/${browserVersion(
          userAgent,
          /(ucbrowser)\/([\d\.]+)/i
        )}`;
      case "Edge":
        return `${browser}/${browserVersion(
          userAgent,
          /(edge|edga|edgios|edg)\/([\d\.]+)/i
        )}`;
      case "GoogleBot":
        return `${browser}/${browserVersion(
          userAgent,
          /(googlebot)\/([\d\.]+)/i
        )}`;
      case "Chromium":
        return `${browser}/${browserVersion(
          userAgent,
          /(chromium)\/([\d\.]+)/i
        )}`;
      case "Firefox":
        return `${browser}/${browserVersion(
          userAgent,
          /(firefox|fxios)\/([\d\.]+)/i
        )}`;
      case "Chrome":
        return `${browser}/${browserVersion(
          userAgent,
          /(chrome|crios)\/([\d\.]+)/i
        )}`;
      case "Safari":
        return `${browser}/${browserVersion(
          userAgent,
          /(safari)\/([\d\.]+)/i
        )}`;
      case "Opera":
        return `${browser}/${browserVersion(
          userAgent,
          /(opera|opr)\/([\d\.]+)/i
        )}`;
      case "IE":
        const version = browserVersion(userAgent, /(trident)\/([\d\.]+)/i);
        // IE version is mapped using trident version
        // IE/8.0 = Trident/4.0, IE/9.0 = Trident/5.0
        return version
          ? `${browser}/${parseFloat(version) + 4.0}`
          : `${browser}/7.0`;
      default:
        return `unknown/0.0.0.0`;
    }
  };

  const isSafari = getBrowser().includes("Safari");

  const handleDateChange = (e) => {
    if (new Date(e) < new Date() && !isSafari) {
      const year = new Date().getFullYear();
      const month = new Date().getMonth() + 1;
      const day = new Date().getDate();
      const hour = new Date().getHours();
      const minute = new Date().getMinutes();

      setDataInput({
        ...dataInput,
        dateSafari: e,
        dateChrome: `${year}-${month < 10 ? `0${month}` : month}-${
          day < 10 ? `0${day}` : day
        }T${hour < 10 ? `0${hour}` : hour}:${
          minute < 10 ? `0${minute}` : minute
        }`,
        tglMulaiAsuransi: moment(e).format("YYYY-MM-DD[T]HH:mm"),
      });
    } else {
      setDataInput({
        ...dataInput,
        dateSafari: e,
        dateChrome: e,
        tglMulaiAsuransi: moment(e).format("YYYY-MM-DD[T]HH:mm"),
      });
    }
  };

  const getCoverage = (e) => {
    if (e > 250000000) {
      return 500000000;
    } else if (e > 200000000) {
      return 250000000;
    } else if (e > 50000000) {
      return 100000000;
    } else if (e > 25000000) {
      return 50000000;
    } else {
      return 25000000;
    }
  };

  const getAmount = () => {
    setLoading(true);
    axios
      .post(`${BASE_URL}/api/v1/product/easytrip/hitung`, {
        uangPertanggungan: dataInput.uangPertanggungan,
        kodeMasaAsuransi: state.kodeMasaAsuransi,
        kodeProduk: state.kodeProduk,
      })
      .then(({ data }) => {
        setLoading(false);
        if(data?.data?.premi < 0) {
          alert('Mohon periksa kembali data anda');
        } else {
          navigate("/product/easy-trip/calculate", {
            state: { ...state, ...data.data, ...dataInput },
          });
        }
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  return (
    <Container className="bg-primary-90" isPrimary>
      <div className="relative h-max max-h-[240px] mb-16 lg:mb-24">
        <img
          className="block lg:hidden"
          src={easyTripHeader}
          alt=""
          width="100%"
        />
        <img className="hidden lg:block" src={easyTripHeaderDesktop} alt="" />
        <div
          className="w-auto lg:w-full absolute top-0 left-8 sm:left-24 lg:left-0 pr-12 sm:pr-24 md:pr-48 lg:pr-0 h-[85%] lg:h-full flex flex-col justify-center items-start lg:items-center text-base sm:text-xl md:text-2xl font-bold text-white"
          style={{ textShadow: "2px 2px #FAAD47" }}
        >
          <div className="mb-0 sm:mb-2 w-full text-center">
            Hi LifeFriends!
          </div>
          <div className="w-full lg:w-2/3 text-center">
            Lengkapi data berikut untuk mendapatkan proteksi Easy Trip kamu!
          </div>
        </div>
        <div
          role="button"
          onClick={() =>
            navigate("/product/easy-trip/form-detail-trip/transport")
          }
          className="absolute top-2 sm:top-8 lg:top-12 left-2 sm:left-8 lg:left-12"
        >
          <Icon
            className="text-white scale-[0.8] sm:scale-150 lg:scale-[2]"
            icon={chevronLeft}
          />
        </div>
      </div>
      <div className="mb-24 md:mb-36">
        <div className="w-full flex flex-col items-center -mt-12 md:mt-0">
          <div className="text-white text-base md:text-[28px] font-semibold text-center">
            Dimulai pada Tanggal
          </div>
          {isSafari ? (
            <div className="relative max-w-[320px] md:max-w-[440px] w-full px-6 py-2 text-base md:text-2xl font-semibold mt-2 mb-6 text-center">
              <DateTimePicker
                className="relative"
                calendarAriaLabel="Toggle calendar"
                clearAriaLabel="Clear value"
                dayAriaLabel="Day"
                hourAriaLabel="Hour"
                minuteAriaLabel="Minute"
                monthAriaLabel="Month"
                nativeInputAriaLabel="Date and time"
                onChange={(e) => handleDateChange(e)}
                secondAriaLabel="Second"
                value={dataInput?.dateSafari}
                yearAriaLabel="Year"
                minDate={new Date()}
                closeWidgets={false}
                clearIcon={null}
                format="y-MM-dd HH:mm"
              />
            </div>
          ) : (
            <div className="relative max-w-[320px] md:max-w-[440px] w-full px-6 py-2 text-base md:text-2xl font-semibold mt-2 mb-6">
              <input
                type="datetime-local"
                min="2022-04-29"
                className="w-full h-10 px-4 py-2 focus:outline-none rounded-lg"
                value={dataInput?.dateChrome}
                onChange={(e) => handleDateChange(e?.target?.value)}
              />
            </div>
          )}

          <div className="text-white text-base md:text-[28px] font-semibold text-center mb-4">
            <div className="mb-1 lg:mb-4">
              Uang Pertanggungan yang saya pilih
            </div>
            <div>
              {dataInput?.uangPertanggungan
                ?.toString()
                ?.replace(/\B(?=(\d{3})+(?!\d))/g, ".")}
            </div>
          </div>
          <div className="mb-12 px-6 md:px-0 w-full max-w-[640px] flex gap-2 md:gap-8 text-white text-base md:text-2xl font-semibold">
            <div>50 Juta</div>
            <div className="flex flex-grow items-center">
              <input
                className="w-full slider"
                type="range"
                min={50000000}
                max={500000000}
                value={dataInput?.uangPertanggungan}
                onChange={(e) =>
                  setDataInput({
                    ...dataInput,
                    uangPertanggungan: getCoverage(e?.target?.value),
                  })
                }
              />
            </div>
            <div>500 Juta</div>
          </div>
          <div className="mb-4 md:mb-6 text-white text-sm sm:text-base lg:text-xl font-semibold">
            dengan rincian manfaat:
          </div>
          <div className="mb-6 md:mb-12 flex flex-col md:flex-row w-full max-w-[720px] text-white text-sm sm:text-base lg:text-xl font-semibold">
            <div className="mb-6 md:mb-0 w-full md:w-1/2 px-4 border-r-0 md:border-r-2 border-white flex flex-col justify-between">
              <div className="text-center">
                Meninggal Dunia akibat Kecelakaan
              </div>
              <div className="text-center">
                <span className="text-2xl md:text-[40px]">100%</span> dari Uang
                Pertanggungan
              </div>
            </div>
            <div className="w-full md:w-1/2 px-4 border-l-0 md:border-l-2 border-white flex flex-col justify-between">
              <div className="text-center">Family Income Benefit</div>
              <div className="text-center">
                <span className="text-2xl md:text-[40px]">15%</span> dari Uang
                Pertanggungan
              </div>
            </div>
          </div>
          <div className="px-6 lg:px-0 w-full max-w-[720px] text-white font-semibold text-sm sm:text-base lg:text-xl">
            <div>
              Dapatkan juga Manfaat Cacat Tetap Total, Biaya Pengobatan, dan
              Biaya Repatriasi sesuai dengan Uang Pertanggungan yang kamu pilih.
              Klik{" "}
              <span className="text-secondary-90 underline whitespace-nowrap cursor-pointer">
                <a href={RIPLAY} download="RIPLAY Easy Trip.pdf">
                  di sini
                </a>
              </span>{" "}
              untuk detail proteksi kamu.
            </div>
            <div>*syarat dan ketentuan berlaku</div>
          </div>
          <div className="w-full flex flex-col justify-center items-center mt-8 lg:mt-16">
            <Button onClick={getAmount} text="Hitung" disable={loading} />
          </div>
        </div>
      </div>
    </Container>
  );
}
