/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import {
  easyTripHeader,
  easyTripHeaderDesktop,
} from "../../../../assets/images";
import { Button, Container, Dropdown } from "../../../../components";
import { Icon } from "react-icons-kit";
import { chevronLeft } from "react-icons-kit/fa/chevronLeft";
import { useNavigate } from "react-router-dom";

export default function Page() {
  const navigate = useNavigate();
  const [dataInput, setDataInput] = useState({
    trip: {},
    transport: {},
    protection: {},
    jenisPerjalanan: "",
    jenisTransportasi: "",
    kodeMasaAsuransi: "H01",
    kodeProduk: "IFGETRIP",
  });

  useEffect(() => {
    const dataTransport = localStorage.getItem("data_transport");
    if (dataTransport) {
      setDataInput(JSON.parse(dataTransport));
    }
  }, []);

  return (
    <Container className="bg-primary-90" isPrimary>
      <div className="relative h-max max-h-[240px] mb-4 lg:mb-24">
        <img className="block lg:hidden" src={easyTripHeader} alt="" width="100%" />
        <img className="hidden lg:block" src={easyTripHeaderDesktop} alt="" />
        <div
          className="w-auto lg:w-full absolute top-0 left-8 sm:left-24 lg:left-0 pr-12 sm:pr-24 md:pr-48 lg:pr-0 h-[85%] lg:h-full flex flex-col justify-center items-start lg:items-center text-base sm:text-xl md:text-2xl font-bold text-white"
          style={{ textShadow: "2px 2px #FAAD47" }}
        >
          <div className="mb-0 sm:mb-2 w-full text-center">
            Hi LifeFriends!
          </div>
          <div className="w-full lg:w-2/3 text-center">
            Lengkapi data berikut untuk mendapatkan proteksi Easy Trip kamu!
          </div>
        </div>
        <div
          role="button"
          onClick={() => navigate(-1)}
          className="absolute top-2 sm:top-8 lg:top-12 left-2 sm:left-8 lg:left-12"
        >
          <Icon
            className="text-white scale-[0.8] sm:scale-150 lg:scale-[2]"
            icon={chevronLeft}
          />
        </div>
      </div>
      <div className="mb-24 md:mb-36">
        <div className="w-full flex flex-col gap-10 items-center">
          <Dropdown
            title="Jenis Perjalanan"
            value={dataInput?.trip}
            onSelect={(e) =>
              setDataInput({
                ...dataInput,
                trip: e,
                jenisPerjalanan: e?.label,
              })
            }
            options={[{ label: "Luar Negeri" }, { label: "Dalam Negeri" }]}
          />
          <Dropdown
            title="Jenis Transportasi"
            value={dataInput?.transport}
            onSelect={(e) =>
              setDataInput({
                ...dataInput,
                transport: e,
                jenisTransportasi: e?.label,
              })
            }
            options={[{ label: "Darat" }, { label: "Udara" }, { label: "Laut" }]}
          />
          <Dropdown
            title="Masa Perlindungan"
            value={dataInput?.protection}
            onSelect={(e) =>
              setDataInput({
                ...dataInput,
                protection: e,
                kodeMasaAsuransi: e.value,
              })
            }
            options={[
              { value: "H01", label: "1 hari" },
              { value: "M01", label: "1 minggu" },
              { value: "B01", label: "1 bulan" },
              { value: "T01", label: "1 tahun" },
            ]}
          />
          <div className="mt-4">
            <Button
              onClick={() => {
                navigate("/product/easy-trip/form-detail-trip/coverage", {
                  state: dataInput,
                });
                localStorage.setItem("data_transport", JSON.stringify(dataInput));
              }}
              text="Lanjut"
              disable={
                !dataInput?.trip?.label ||
                !dataInput?.transport?.label ||
                !dataInput?.protection?.label
              }
            />
          </div>
        </div>
      </div>
    </Container>
  );
}
