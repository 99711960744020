import { Routes, Route } from "react-router-dom";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import {
  Calculate,
  FormDetailTripCoverage,
  FormDetailTripTransport,
  FormDetailUser,
  Home,
  PaymentSuccess,
  Product,
  SyaratDanKetentuan
} from "./modules";
import { RECAPTCHA_KEY } from "./utils/constants";

function App() {
  return (
    <GoogleReCaptchaProvider reCaptchaKey={RECAPTCHA_KEY}>
      <Routes>
        <Route exact path="/" element={<Home />} />
        <Route exact path="/product/easy-trip" element={<Product />} />
        <Route
          exact
          path="/product/easy-trip/form-detail-trip/transport"
          element={<FormDetailTripTransport />}
        />
        <Route
          exact
          path="/product/easy-trip/form-detail-trip/coverage"
          element={<FormDetailTripCoverage />}
        />
        <Route
          exact
          path="/product/easy-trip/calculate"
          element={<Calculate />}
        />
        <Route
          exact
          path="/product/easy-trip/form-detail-user"
          element={<FormDetailUser />}
        />
        <Route
          exact
          path="/payment/success/:id"
          element={<PaymentSuccess />}
        />
        <Route
          exact
          path="/syarat-dan-ketentuan"
          element={<SyaratDanKetentuan />}
        />
      </Routes>
    </GoogleReCaptchaProvider>
  );
}

export default App;
