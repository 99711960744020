import cx from "classnames";

// const monthName = [
//   "Januari",
//   "Februari",
//   "Maret",
//   "April",
//   "Mei",
//   "Juni",
//   "Juli",
//   "Agustus",
//   "September",
//   "Oktober",
//   "November",
//   "Desember",
// ];

// const browserVersion = (userAgent,regex) => {
//   return userAgent.match(regex) ? userAgent.match(regex)[2] : null;
// }

// const getBrowser = () => {
//   const userAgent = navigator.userAgent;
//   let browser = "unkown";
//   // Detect browser name
//   browser = (/ucbrowser/i).test(userAgent) ? 'UCBrowser' : browser;
//   browser = (/edg/i).test(userAgent) ? 'Edge' : browser;
//   browser = (/googlebot/i).test(userAgent) ? 'GoogleBot' : browser;
//   browser = (/chromium/i).test(userAgent) ? 'Chromium' : browser;
//   browser = (/firefox|fxios/i).test(userAgent) && !(/seamonkey/i).test(userAgent) ? 'Firefox' : browser;
//   browser = (/; msie|trident/i).test(userAgent) && !(/ucbrowser/i).test(userAgent) ? 'IE' : browser;
//   browser = (/chrome|crios/i).test(userAgent) && !(/opr|opera|chromium|edg|ucbrowser|googlebot/i).test(userAgent) ? 'Chrome' : browser;;
//   browser = (/safari/i).test(userAgent) && !(/chromium|edg|ucbrowser|chrome|crios|opr|opera|fxios|firefox/i).test(userAgent) ? 'Safari' : browser;
//   browser = (/opr|opera/i).test(userAgent) ? 'Opera' : browser;

//   // detect browser version
//   switch (browser) {
//       case 'UCBrowser': return `${browser}/${browserVersion(userAgent,/(ucbrowser)\/([\d\.]+)/i)}`;
//       case 'Edge': return `${browser}/${browserVersion(userAgent,/(edge|edga|edgios|edg)\/([\d\.]+)/i)}`;
//       case 'GoogleBot': return `${browser}/${browserVersion(userAgent,/(googlebot)\/([\d\.]+)/i)}`;
//       case 'Chromium': return `${browser}/${browserVersion(userAgent,/(chromium)\/([\d\.]+)/i)}`;
//       case 'Firefox': return `${browser}/${browserVersion(userAgent,/(firefox|fxios)\/([\d\.]+)/i)}`;
//       case 'Chrome': return `${browser}/${browserVersion(userAgent,/(chrome|crios)\/([\d\.]+)/i)}`;
//       case 'Safari': return `${browser}/${browserVersion(userAgent,/(safari)\/([\d\.]+)/i)}`;
//       case 'Opera': return `${browser}/${browserVersion(userAgent,/(opera|opr)\/([\d\.]+)/i)}`;
//       case 'IE': const version = browserVersion(userAgent,/(trident)\/([\d\.]+)/i);
//           // IE version is mapped using trident version 
//           // IE/8.0 = Trident/4.0, IE/9.0 = Trident/5.0
//           return version ? `${browser}/${parseFloat(version) + 4.0}` : `${browser}/7.0`;
//       default: return `unknown/0.0.0.0`;
//   }
// }

// const isSafari = getBrowser().includes('Safari');

// const renderDate = (e) => {
//   if (e) {
//     const [year, month, day] = e?.split("-");
//     return `${day} ${monthName[+month - 1]} ${year}`;
//   } else {
//     const year = new Date().getFullYear();
//     const month = new Date().getMonth();
//     const day = new Date().getDate();
//     return `${day} ${monthName[+month]} ${year}`;
//   }
// };

export default function Component({
  type = "text",
  title,
  size,
  isPrimary = true,
  placeholder,
  optional,
  onChange,
  value,
  maxLength,
  invalidMessage,
}) {
  return (
    <>
      {isPrimary ? (
        <div className="flex items-center text-white text-sm sm:text-base md:text-xl lg:text-2xl gap-2 md:gap-4 font-semibold">
          <div>{title}</div>
          <div
            className={cx("", {
              "w-48 sm:w-96": size === "xl",
              "w-36 sm:w-72": size === "lg",
              "w-24 sm:w-48": !size,
            })}
          >
            {type === "date" ? (
              <div className="relative">
                <input
                  type={type}
                  maxLength={maxLength}
                  value={value}
                  onChange={(e) => onChange(e)}
                  className="text-center text-sm sm:text-base md:text-lg lg:text-xl w-full active:bg-primary-90 bg-primary-90 focus:outline-none border-b-2 border-white"
                />
                <div className="absolute text-xs sm:text-sm md:text-base lg:text-lg font-normal">
                  {invalidMessage}
                </div>
                {/* {type === "date" && !isSafari ? (
                  <div className="text-center absolute top-0 h-full bg-primary-90 w-[90%] text-sm sm:text-base md:text-lg lg:text-xl border-b-2 border-white">
                    {renderDate(value)}
                  </div>
                ) : null} */}
              </div>
            ) : (
              <div className="relative">
                <input
                  type={type}
                  maxLength={maxLength}
                  value={value}
                  onChange={(e) => onChange(e)}
                  className="text-center text-sm sm:text-base md:text-lg lg:text-xl w-full active:bg-primary-90 bg-primary-90 focus:outline-none border-b-2 border-white"
                />
                <div className="absolute text-xs sm:text-sm md:text-base lg:text-lg font-normal">
                  {invalidMessage}
                </div>
              </div>
            )}
          </div>
        </div>
      ) : (
        <>
          <div className="relative w-full lg:w-[720px] h-10 md:h-[40px] p-2 flex rounded-full gap-8 bg-white">
            <input
              type={type}
              onChange={(e) => onChange(e)}
              maxLength={maxLength}
              value={value}
              className="text-sm md:text-base flex-grow focus:outline-none px-2 font-medium bg-transparent"
              placeholder={placeholder}
            />
            {optional ? (
              <div className="text-sm md:text-base text-neutral-20">
                (Jika ada)
              </div>
            ) : null}
            {/* {type === "date" && !isSafari ? (
              <div className="absolute top-0 left-0 h-8 w-[90%] bg-white px-4 py-2 rounded-full">
                {renderDate(value)}
              </div>
            ) : null} */}
          </div>
          {invalidMessage ? (
            <div className="w-full lg:w-[720px] font-semibold -mt-4 text-xs md:text-sm text-white px-4">
              {invalidMessage}
            </div>
          ) : null}
        </>
      )}
    </>
  );
}
