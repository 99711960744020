import Icon from 'react-icons-kit';
import { useNavigate, useLocation } from 'react-router-dom';
import {
  easyTripIcon2,
  frameVector,
  lifeBackground,
} from '../../../../assets/images';
import { Button, Container } from '../../../../components';
import { chevronLeft } from 'react-icons-kit/fa/chevronLeft';

export default function Page() {
  const navigate = useNavigate();
  const { state } = useLocation();

  return (
    <Container className='relative bg-white'>
      <div
        role='button'
        onClick={() => navigate(-1)}
        className='z-10 absolute top-2 sm:top-8 lg:top-12 left-2 sm:left-8 lg:left-12'
      >
        <Icon
          className='text-primary-90 scale-[0.8] sm:scale-150 lg:scale-[2]'
          icon={chevronLeft}
        />
      </div>
      <img
        className='absolute top-0 z-10 right-0 h-[160px] md:h-[240px] lg:h-[280px] xl:h-[320px]'
        src={frameVector}
        alt=''
      />
      <img
        className='absolute bottom-0 z-10 left-0 h-[160px] md:h-[240px] lg:h-[280px] xl:h-[320px] rotate-180'
        src={frameVector}
        alt=''
      />
      <div className='w-full absolute top-0'>
        <img
          className='max-h-screen absolute right-0 z-2'
          src={lifeBackground}
          alt=''
        />
      </div>
      <div className='w-full h-full flex flex-col gap-6 md:gap-10 lg:gap-12 justify-center items-center px-6 md:px-0'>
        <img
          className='z-10 w-[120px] md:w-[180px] xl:w-[220px]'
          src={easyTripIcon2}
          alt=''
        />
        <div className='relative'>
          <div className='text-white font-bold text-xl lg:text-3xl xl:text-[32px] px-6 md:px-12 xl:px-16 py-2 md:py-6 xl:py-8 bg-primary-90 rounded-full'>
            Berikut premi yang harus anda bayarkan
          </div>
          <div className='flex justify-center'>
            <div className='absolute w-0 h-0 -top-6 md:-top-8 border-r-[24px] border-l-[24px] md:border-r-[16px] md:border-l-[16px] border-r-transparent border-l-transparent border-b-[32px] mdborder-b-[48px] border-b-primary-90'></div>
          </div>
        </div>
        <div className='font-bold text-2xl md:text-4xl xl:text-5xl'>
          Rp {state?.premi?.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, '.')}
        </div>
        <div className='z-10'>
          <Button
            onClick={() =>
              navigate('/product/easy-trip/form-detail-user', { state: state })
            }
            text='Lanjut'
          />
        </div>
      </div>
      <div className='pl-16 md:pl-0 text-primary-90 w-full text-[8px] md:text-xs font-semibold text-right absolute py-2 px-4 bottom-0'>
        <div>ifg-life.id | Call Center 1500176 | WA +62 811-1372-848</div>
        <div>
          PT Asuransi Jiwa IFG terdaftar dan diawasi oleh Otoritas Jasa
          Keuangan.
        </div>
      </div>
    </Container>
  );
}
