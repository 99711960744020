import cx from 'classnames';
import ReactLoading from 'react-loading';

export default function Component({ children, className, loading,parentClassName }) {
  return (
    <main className={cx('w-full flex justify-center bg-primary-90',parentClassName)}>
      <div className={cx('w-full min-h-screen max-w-[1440px]', className)}>
        {children}
      </div>
      {loading ? (
        <div className='absolute top-0 grid place-content-center w-full h-screen bg-black/50'>
          <ReactLoading type='spin' />
        </div>
      ) : null}
    </main>
  );
}
