/* eslint-disable react-hooks/exhaustive-deps */
import cx from "classnames";
import { useEffect, useState } from "react";
import {
  easyTripHeader,
  easyTripHeaderDesktop,
} from "../../../../assets/images";
import { Icon } from "react-icons-kit";
import { chevronLeft } from "react-icons-kit/fa/chevronLeft";
import { check } from "react-icons-kit/fa/check";
import {
  Button,
  Container,
  Dropdown,
  InputSearch,
  TextInput,
} from "../../../../components";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import { BASE_URL } from "../../../../utils/constants";
import { isValidEmail } from "../../../../utils/helpers";
import SNK from "../../../../assets/pdf/snk.pdf";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import moment from "moment";

export default function Page() {
  const navigate = useNavigate();
  const { state } = useLocation();
  const { executeRecaptcha } = useGoogleReCaptcha();
  const [dataInput, setDataInput] = useState({
    nama1: null,
    tglLahir1: null,
    numberKTP: null,
    address: null,
    kodeProvinsi1: null,
    province: {},
    kodePos1: null,
    noHp1: null,
    email: null,
    insuranceFor: null,
    isCorrentData: false,
    isUserAgree: false,
    otherPersonInsurance: {
      numberKTP: null,
      name: null,
      placeBirth: null,
      birthDate: null,
      heirName: null,
      heirEmail: null,
    },
    numberKTPRef: null,
    //
    kodeTujuanAsuransi: null,
    nama2: null,
    tglLahir2: null,
    noKtp2: null,
    emailAhliWaris: null,
    idTransaksi: null,
    kodeProduk: null,
    jenisPerjalanan: null,
    jenisTransportasi: null,
    kodeMasaAsuransi: null,
    uangPertanggungan: null,
    premi: null,
    tglMulaiAsuransi: null,
  });
  const [listProvince, setListProvince] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    axios
      .get(BASE_URL + "/api/v1/core/propinsi")
      .then(({ data }) => {
        const list = data?.data
          ?.map((e) => {
            return { ...e, label: e?.namaPropinsi };
          })
          .sort((a, b) =>
            a.namaPropinsi > b.namaPropinsi
              ? 1
              : b.namaPropinsi > a.namaPropinsi
              ? -1
              : 0
          );
        setListProvince(list);
      })
      .catch((err) => {});

    setDataInput({
      ...dataInput,
      tglLahir1: moment().format("YYYY-MM-DD"),
      tglLahir2: moment().format("YYYY-MM-DD"),
    });
  }, []);

  const setSubmit = async () => {
    if (
      /<\/?[a-z][\s\S]*>/i.test(dataInput.nama1) === true ||
      /<\/?[a-z][\s\S]*>/i.test(dataInput.alamat1) === true ||
      /<\/?[a-z][\s\S]*>/i.test(dataInput.nama2) === true ||
      dataInput.noHp1 === "" ||
      dataInput.noHp1 === null ||
      dataInput.alamat1 === "" ||
      dataInput.alamat1 === null ||
      !isValidEmail(dataInput?.emailAhliWaris) ||
      !isValidEmail(dataInput?.email) ||
      (dataInput?.insuranceFor?.label === "Diri Sendiri" &&
        (moment(dataInput?.tglLahir1).fromNow().split(" ")[0] < 17 ||
          moment(dataInput?.tglLahir1).fromNow().split(" ")[0] > 71 ||
          moment(dataInput?.tglLahir1).fromNow().split(" ")[1] !== "years")) ||
      (dataInput?.insuranceFor?.label === "Orang Lain" &&
        (moment().diff(dataInput?.tglLahir2, "days") < 31 ||
          moment().diff(dataInput?.tglLahir2, "years") > 70))
    ) {
      return alert("Periksa kembali data Anda");
    }
    setLoading(true);
    const token = await executeRecaptcha("submitInsurance");
    const data = {
      nama1: dataInput.nama1,
      tglLahir1: dataInput.tglLahir1,
      noKtp1: dataInput.noKtp1,
      alamat1: dataInput.alamat1,
      kodeProvinsi1: dataInput.province.kdPropinsi,
      kodePos1: dataInput.kodePos1,
      noHp1: dataInput.noHp1,
      email: dataInput.email,
      kodeTujuanAsuransi: dataInput.insuranceFor.value,
      nama2: dataInput.nama2,
      tglLahir2:
        dataInput?.insuranceFor?.label === "Diri Sendiri"
          ? null
          : dataInput.tglLahir2,
      noKtp2: dataInput.noKtp2 === "" ? null : dataInput.noKtp2,
      namaTertanggung: dataInput.otherPersonInsurance.name,
      tempatLahir: dataInput.otherPersonInsurance.placeBirth,
      kodeReferal: dataInput.numberKTPRef,
      emailAhliWaris: dataInput.emailAhliWaris,
      idTransaksi: "",
      kodeProduk: state?.kodeProduk,
      jenisPerjalanan: state?.jenisPerjalanan,
      jenisTransportasi: state?.jenisTransportasi,
      kodeMasaAsuransi: state?.kodeMasaAsuransi,
      uangPertanggungan: state?.uangPertanggungan,
      premi: state?.premi,
      tglMulaiAsuransi: `${state?.tglMulaiAsuransi?.replace("T", " ")}:00`,
      response: token,
    };
    return axios
      .post(`${BASE_URL}/api/v1/product/easytrip/submit`, data)
      .then(({ data }) => {
        if (data?.statusCode === 200) {
          window.location.replace(data?.data?.url);
        } else {
          alert("Periksa kembali data Anda");
          setLoading(false);
        }
      })
      .catch((err) => {
        alert("Terjadi kesalahan, coba beberapa saat lagi.");
        return setLoading(false);
      });
  };

  return (
    <Container className="bg-primary-90" isPrimary>
      <div className="relative h-max max-h-[360px] mb-8 md:mb-16">
        <img
          className="block lg:hidden"
          src={easyTripHeader}
          alt=""
          width="100%"
        />
        <img className="hidden lg:block" src={easyTripHeaderDesktop} alt="" />
        <div
          className="w-auto lg:w-full absolute top-0 left-8 sm:left-24 lg:left-0 pr-12 sm:pr-24 md:pr-48 lg:pr-0 h-[85%] lg:h-full flex flex-col justify-center items-start lg:items-center text-base sm:text-xl md:text-2xl font-bold text-white"
          style={{ textShadow: "2px 2px #FAAD47" }}
        >
          <div className="mb-0 sm:mb-2 w-full text-center">Hi LifeFriends!</div>
          <div className="w-full lg:w-1/2 text-center">
            Lengkapi data berikut untuk mendapatkan proteksi Easy Trip kamu!
          </div>
        </div>
        <div
          role="button"
          onClick={() => navigate(-2)}
          className="absolute top-2 sm:top-8 lg:top-12 left-2 sm:left-8 lg:left-12"
        >
          <Icon
            className="text-white scale-[0.8] sm:scale-150 lg:scale-[2]"
            icon={chevronLeft}
          />
        </div>
      </div>
      <div className="w-full flex flex-col gap-8 items-center mb-16 md:mb-32 px-4 sm:px-12 lg:px-0 lg:pb-44">
        <TextInput
          title="Nama Saya"
          size="xl"
          value={dataInput?.nama1}
          onChange={(e) =>
            setDataInput({
              ...dataInput,
              nama1: e?.target?.value
                ?.split("")
                ?.filter((e) => !(e === "'" || e === '"'))
                ?.join(""),
            })
          }
        />
        {dataInput?.nama1 && dataInput?.nama1?.trim()?.length !== 0 ? (
          <div
            className={
              (dataInput?.insuranceFor?.label === "Diri Sendiri" &&
                (moment(dataInput?.tglLahir1).fromNow().split(" ")[0] < 17 ||
                  moment(dataInput?.tglLahir1).fromNow().split(" ")[0] > 71 ||
                  moment(dataInput?.tglLahir1).fromNow().split(" ")[1] !==
                    "years")) ||
              (dataInput?.insuranceFor?.label === "Orang Lain" &&
                (moment(dataInput?.tglLahir1).fromNow().split(" ")[0] < 17 ||
                  moment(dataInput?.tglLahir1).fromNow().split(" ")[1] !==
                    "years"))
                ? "mb-6"
                : ""
            }
          >
            <TextInput
              type="date"
              title="Tanggal Lahir"
              size="lg"
              value={dataInput?.tglLahir1}
              onChange={(e) =>
                setDataInput({
                  ...dataInput,
                  tglLahir1:
                    new Date(e?.target?.value) < new Date()
                      ? e?.target?.value
                      : moment().format("YYYY-MM-DD"),
                })
              }
              invalidMessage={
                dataInput?.insuranceFor?.label === "Diri Sendiri" &&
                (moment(dataInput?.tglLahir1).fromNow().split(" ")[0] < 17 ||
                  moment(dataInput?.tglLahir1).fromNow().split(" ")[0] > 71 ||
                  moment(dataInput?.tglLahir1).fromNow().split(" ")[1] !==
                    "years")
                  ? "Pemegang Polis berumur diantara 17 tahun dan 71 tahun"
                  : dataInput?.insuranceFor?.label === "Orang Lain" &&
                    (moment(dataInput?.tglLahir1).fromNow().split(" ")[0] <
                      17 ||
                      moment(dataInput?.tglLahir1).fromNow().split(" ")[1] !==
                        "years")
                  ? "Pemegang Polis berumur 17 tahun keatas"
                  : ""
              }
            />
          </div>
        ) : null}
        {dataInput?.tglLahir1 &&
        dataInput?.nama1 &&
        dataInput?.nama1?.trim()?.length !== 0 ? (
          <TextInput
            type="number"
            title="Nomor Identitas KTP"
            size="lg"
            maxLength={16}
            value={dataInput?.noKtp1}
            onChange={(e) =>
              setDataInput({ ...dataInput, noKtp1: e?.target?.value })
            }
            invalidMessage={
              String(dataInput?.noKtp1)?.length !== 16
                ? "KTP harus 16 angka"
                : ""
            }
          />
        ) : null}
        {String(dataInput?.noKtp1)?.length === 16 ? (
          <TextInput
            title="Alamat Tinggal di"
            size="xl"
            value={dataInput?.alamat1}
            onChange={(e) =>
              setDataInput({ ...dataInput, alamat1: e?.target?.value })
            }
          />
        ) : null}
        {dataInput?.alamat1 && dataInput?.alamat1.trim().length !== 0 ? (
          <div className="flex flex-col lg:flex-row gap-8 items-center">
            <InputSearch
              title="Provinsi"
              options={listProvince}
              value={dataInput?.province}
              onSelect={(e) =>
                setDataInput({
                  ...dataInput,
                  province: e,
                  kodeProvinsi1: e?.kdPropinsi,
                })
              }
            />
            <TextInput
              type="number"
              title="Kode Pos"
              size="lg"
              value={dataInput?.kodePos1}
              onChange={(e) =>
                setDataInput({ ...dataInput, kodePos1: e?.target?.value })
              }
              invalidMessage={
                String(dataInput?.kodePos1)?.length !== 5
                  ? "Kodepos harus 5 angka"
                  : ""
              }
            />
          </div>
        ) : null}
        {dataInput?.province?.label &&
        String(dataInput?.kodePos1)?.length === 5 ? (
          <TextInput
            type="number"
            title="Nomor HP Saya"
            size="lg"
            value={dataInput?.noHp1}
            onChange={(e) =>
              setDataInput({ ...dataInput, noHp1: e?.target?.value })
            }
            invalidMessage={
              dataInput?.noHp1?.trim()?.length <= 5
                ? "Format hp tidak sesuai"
                : ""
            }
          />
        ) : null}
        {dataInput?.noHp1 ? (
          <TextInput
            title="dan Email"
            type="email"
            size="lg"
            value={dataInput?.email}
            onChange={(e) =>
              setDataInput({ ...dataInput, email: e?.target?.value })
            }
            invalidMessage={
              !isValidEmail(dataInput?.email) ? "Format email salah" : ""
            }
          />
        ) : null}
        {isValidEmail(dataInput?.email) ? (
          <div className="flex flex-col lg:flex-row gap-4 lg:gap-8 items-center">
            <div className="text-white text-sm sm:text-base md:text-xl lg:text-2xl font-semibold">
              Saya mengambil Asuransi untuk
            </div>
            <div className="w-full lg:w-96">
              <Dropdown
                value={dataInput?.insuranceFor}
                onSelect={(e) =>
                  setDataInput({ ...dataInput, insuranceFor: e })
                }
                options={[
                  { value: 0, label: "Diri Sendiri" },
                  { value: 1, label: "Orang Lain" },
                ]}
              />
            </div>
          </div>
        ) : null}
        {dataInput?.insuranceFor?.label ? (
          <div className="w-full flex flex-col items-center gap-4">
            {dataInput?.insuranceFor?.label === "Orang Lain" ? (
              <>
                <TextInput
                  placeholder="Nomor KTP"
                  type="number"
                  isPrimary={false}
                  value={dataInput?.noKtp2}
                  onChange={(e) =>
                    setDataInput({
                      ...dataInput,
                      noKtp2: e?.target?.value,
                    })
                  }
                  invalidMessage={
                    String(dataInput?.noKtp2)?.length !== 16
                      ? "KTP harus 16 angka"
                      : ""
                  }
                />
                <TextInput
                  placeholder="Nama Lengkap"
                  isPrimary={false}
                  value={dataInput?.otherPersonInsurance?.name}
                  onChange={(e) =>
                    setDataInput({
                      ...dataInput,
                      otherPersonInsurance: {
                        ...dataInput?.otherPersonInsurance,
                        name: e?.target?.value,
                      },
                    })
                  }
                  invalidMessage="Hubungan keluarga yang dapat dipertanggungjawabkan secara sah dalam hukum"
                />
                <TextInput
                  placeholder="Tempat Lahir"
                  isPrimary={false}
                  value={dataInput?.otherPersonInsurance?.placeBirth}
                  onChange={(e) =>
                    setDataInput({
                      ...dataInput,
                      otherPersonInsurance: {
                        ...dataInput?.otherPersonInsurance,
                        placeBirth: e?.target?.value,
                      },
                    })
                  }
                />
                <TextInput
                  placeholder="Tanggal Lahir"
                  type="date"
                  isPrimary={false}
                  value={dataInput?.tglLahir2}
                  onChange={(e) =>
                    setDataInput({
                      ...dataInput,
                      tglLahir2:
                        new Date(e?.target?.value) < new Date()
                          ? e?.target?.value
                          : moment().format("YYYY-MM-DD"),
                    })
                  }
                  invalidMessage={
                    moment().diff(dataInput?.tglLahir2, "days") < 31 ||
                    moment().diff(dataInput?.tglLahir2, "years") > 70
                      ? "Tertanggung berumur diantara 30 hari dan 71 tahun"
                      : ""
                  }
                />
              </>
            ) : null}
            <TextInput
              placeholder="Nama Ahli Waris"
              isPrimary={false}
              value={dataInput?.nama2}
              onChange={(e) =>
                setDataInput({
                  ...dataInput,
                  nama2: e?.target?.value,
                })
              }
              invalidMessage={
                dataInput?.insuranceFor?.label === "Diri Sendiri" &&
                dataInput?.nama1 === dataInput?.nama2
                  ? "Nama ahli waris tidak boleh sama dengan yang mengajukan"
                  : dataInput?.nama2?.trim()?.length === 0
                  ? "Data tidak boleh kosong"
                  : ""
              }
            />
            <TextInput
              placeholder="Email Ahli Waris"
              type="email"
              isPrimary={false}
              value={dataInput?.emailAhliWaris}
              onChange={(e) =>
                setDataInput({
                  ...dataInput,
                  emailAhliWaris: e?.target?.value,
                })
              }
              invalidMessage={
                !isValidEmail(dataInput?.emailAhliWaris)
                  ? "Format email salah"
                  : dataInput?.insuranceFor?.label === "Diri Sendiri" &&
                    dataInput?.email === dataInput?.emailAhliWaris
                  ? "Email ahli waris tidak boleh sama dengan yang mengajukan"
                  : ""
              }
            />
            <TextInput
              placeholder="Kode Referal"
              optional
              isPrimary={false}
              value={dataInput?.numberKTPRef}
              onChange={(e) =>
                setDataInput({
                  ...dataInput,
                  numberKTPRef: e?.target?.value,
                })
              }
              invalidMessage="No Pegawai / No Agen Pemberi Referensi"
            />
          </div>
        ) : null}
        {dataInput?.insuranceFor?.label === "Diri Sendiri" ||
        (dataInput?.insuranceFor?.label === "Orang Lain" &&
          (!dataInput?.otherPersonInsurance?.numberKTP ||
            !dataInput?.otherPersonInsurance?.name ||
            !dataInput?.otherPersonInsurance?.placeBirth ||
            !dataInput?.otherPersonInsurance?.birthDate)) ? (
          <>
            <div className="flex items-center gap-4 md:gap-8 w-full md:w-[650px]">
              <div>
                <div
                  role="button"
                  onClick={() =>
                    setDataInput({
                      ...dataInput,
                      isCorrentData: !dataInput?.isCorrentData,
                    })
                  }
                  className={cx(
                    "w-8 sm:w-10 md:w-12 h-8 sm:h-10 md:h-12 rounded-full flex justify-center items-center",
                    dataInput?.isCorrentData
                      ? "bg-secondary-80"
                      : "bg-neutral-40"
                  )}
                >
                  <Icon
                    className="text-white scale-100 md:scale-150 -mt-1"
                    icon={check}
                  />
                </div>
              </div>
              <div className="text-white text-sm sm:text-base md:text-xl lg:text-2xl font-semibold">
                Saya telah memastikan bahwa data saya lengkap dan benar.
              </div>
            </div>
            <div className="flex items-center gap-4 md:gap-8 w-full md:w-[650px]">
              <div>
                <div
                  role="button"
                  onClick={() =>
                    setDataInput({
                      ...dataInput,
                      isUserAgree: !dataInput?.isUserAgree,
                    })
                  }
                  className={cx(
                    "w-8 sm:w-10 md:w-12 h-8 sm:h-10 md:h-12 rounded-full flex justify-center items-center",
                    dataInput?.isUserAgree ? "bg-secondary-80" : "bg-neutral-40"
                  )}
                >
                  <Icon
                    className="text-white scale-100 md:scale-150 -mt-1"
                    icon={check}
                  />
                </div>
              </div>
              <div className="text-white text-sm sm:text-base md:text-xl lg:text-2xl font-semibold">
                Dengan mendaftarkan diri, saya menyatakan setuju dengan{" "}
                <a
                  className="underline text-secondary-90 whitespace-nowrap"
                  href={SNK}
                  download="Syarat & Ketentuan.pdf"
                >
                  Syarat & Ketentuan
                </a>
              </div>
            </div>
            <div className="flex flex-col items-center">
              <Button
                onClick={() => {
                  if (dataInput?.insuranceFor?.label === "Diri Sendiri") {
                    if (
                      dataInput?.name1 === dataInput?.nama2 ||
                      dataInput?.email === dataInput?.emailAhliWaris
                    ) {
                    } else {
                      setSubmit();
                    }
                  } else {
                    setSubmit();
                  }
                }}
                text="Bayar"
                disable={
                  !dataInput?.isCorrentData ||
                  !dataInput?.isUserAgree ||
                  loading
                }
              />
              {loading ? (
                <div className="text-white font-normal bottom-8 text-sm">
                  {"Melanjutkan ke proses pembayaran..."}
                </div>
              ) : (
                ""
              )}
            </div>
          </>
        ) : null}
      </div>
    </Container>
  );
}
