import { useEffect, useState } from 'react';
import Icon from 'react-icons-kit';
import { easyTripPlainHeader, frameVector2 } from '../../../../assets/images';
import { Container } from '../../../../components';
import { check } from 'react-icons-kit/fa/check';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import { BASE_URL } from '../../../../utils/constants';

export default function Page() {
  const navigate = useNavigate();
  const [data, setData] = useState({});
  const params = useParams();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    axios
      .get(BASE_URL + `/api/v1/product/easytrip/id/${params?.id}`)
      .then(({ data }) => {
        setLoading(false);
        if(data?.data != null) {
          setData(data?.data);
        } else {
          navigate(`/product/easy-trip`, { replace: true })
        }
      })
      .catch((err) => {
        setLoading(false);
        navigate(`/product/easy-trip`, { replace: true })
      });
  }, [navigate, params?.id]);

  return (
    <Container
      className='relative flex flex-col items-center bg-white'
      loading={loading}
    >
      <img
        className='absolute bottom-0 right-0 h-[160px] md:h-[240px] lg:h-[280px] xl:h-[320px]'
        style={{zIndex: 0}}
        src={frameVector2}
        alt=''
      />
      <div className='relative'>
        <img src={easyTripPlainHeader} alt='' />
        <div className='text-white font-bold text-[32px] md:text-5xl xl:text-[56px] absolute w-full h-full top-0 grid place-content-center'>
          Complete!
        </div>
      </div>
      <div className='z-10 w-16 lg:w-28 xl:w-32 h-16 lg:h-28 xl:h-32 bg-primary-90 grid place-content-center rounded-full -mt-8 lg:-mt-14 xl:-mt-16'>
        <Icon
          className='scale-[2] lg:scale-[3] xl:scale-[4] text-white'
          icon={check}
        />
      </div>
      <div className='flex flex-col gap-4 md:gap-8 items-center text-center mt-4 text-xl md:text-[28px] mb-16 md:mb-32'>
        <div className='text-xl md:text-[32px]'>
          Rp {data?.PREMI1?.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, '.')}
        </div>
        <div>
          <div className='font-bold md:text-[28px] lg:mb-4 mt-8'>
            Dengan Uang Asuransi
          </div>
          <div>
            Rp{' '}
            {data?.JUAMAINPRODUK?.toString()?.replace(
              /\B(?=(\d{3})+(?!\d))/g,
              '.'
            )}
          </div>
        </div>
        <div>
          <div className='font-bold md:text-[28px] lg:mb-4'>
            Terbayarkan Untuk
          </div>
          <div>{data?.NAMAPEMPOL?.toUpperCase()}</div>
        </div>
        <div>
          <div className='font-bold md:text-[28px] lg:mb-4'>Kode Transaksi</div>
          <div>{data?.IDTRANSAKSI}</div>
        </div>
        <button
          onClick={() => navigate('/')}
          className='px-8 py-4 bg-primary-90 rounded-3xl text-base md:text-2xl text-white font-semibold'
          style={{ zIndex: 10 }}
        >
          Kembali ke Beranda
        </button>
      </div>
      <div className='pl-16 md:pl-0 text-primary-90 w-full text-[8px] md:text-xs font-semibold text-right absolute py-2 px-4 bottom-0'>
        <div>ifg-life.id | Call Center 1500176 | WA +62 811-1372-848</div>
        <div>
          PT Asuransi Jiwa IFG terdaftar dan diawasi oleh Otoritas Jasa
          Keuangan.
        </div>
      </div>
    </Container>
  );
}
