import { useNavigate } from "react-router-dom";
import { easyTripIcon, lifeBackground } from "../../../assets/images";
import RIPLAY from "../../../assets/pdf/RIPLAY_Easy_Trip_convert_2.pdf";
import { Button, Container } from "../../../components";

export default function Page() {
  const navigate = useNavigate();

  return (
    <Container className="relative bg-white">
      <div className="mt-16 pl-24 md:pl-32 lg:pl-48 pr-6 md:pr-24 lg:pr-32 mb-16">
        <div className="absolute z-10 top-0 left-0 w-12 md:w-16 h-full min-h-screen bg-gradient-to-b from-secondary-90 to-primary-90">
          <div className="absolute top-16 left-4 w-16 md:w-24 h-16 md:h-24 grid place-content-center bg-gradient-to-b from-secondary-90 to-secondary-60 rounded-full">
            <div className="w-10 md:w-16 h-10 md:h-16 grid place-content-center bg-white rounded-full">
              <img className="scale-50 md:scale-75" src={easyTripIcon} alt="" />
            </div>
          </div>
        </div>
        <div className="w-full bg-red-300 absolute top-0 right-0 -ml-24 md:-ml-32 lg:-ml-48">
          <img
            className="max-h-screen absolute right-0"
            src={lifeBackground}
            alt=""
          />
        </div>
        <div className="text-primary-90 font-bold">
          <div className="text-4xl md:text-5xl">Easy</div>
          <div className="text-5xl md:text-[80px]">TRIP</div>
        </div>
        <div className="text-black text-sm md:text-base flex flex-col gap-4 md:gap-6 mb-12 md:mb-16">
          <div>
            Memberikan perlindungan terhadap risiko meninggal dunia karena
            Kecelakaan, Cacat Tetap Total karena Kecelakaan, Biaya Pengobatan
            Medis (Rawat Inap) karena Kecelakaan, Biaya Pengobatan Medis
            Emergency (Rawat Jalan Darurat) karena Kecelakaan, Biaya Repatriasi
            karena Kecelakaan dan Biaya Emergency Transportation Akibat Kecelakaan.
          </div>
          <div className="z-10">
            Ringkasan Informasi Produk dan Layanan (RIPLAY) dapat di{" "}
            <a
              className="underline cursor-pointer text-primary-90"
              href={RIPLAY}
              download="RIPLAY Easy Trip.pdf"
            >
              Download
            </a>{" "}
            disini.
          </div>
          <div className="flex justify-center z-10">
            <Button
              onClick={() => {
                navigate("/product/easy-trip/form-detail-trip/transport");
                localStorage.removeItem("data_transport");
              }}
              text="Pilih"
            />
          </div>
        </div>
      </div>
      <div className="pl-16 md:pl-0 text-primary-90 w-full text-[8px] sm:text-sm md:text-base lg:text-xl xl:text-2xl text-center absolute py-2 bottom-0">
        EasyPro by IFG Life. PT Asuransi Jiwa IFG terdaftar dan diawasi oleh
        OJK.
      </div>
    </Container>
  );
}
